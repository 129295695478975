<template>
    <footer class="relative md:flex md:justify-between md:items-end bg-background dark:bg-dark-background z-20 text-center md:text-left">
        <div class="footer-item">
            <div>© 2021 - 2022 diafischchen</div>
            <div><i>Pokémon</i> und alle entsprechenden Namen sind Marken und © von <i>Nintendo</i> 1996 - 2022</div>
            <div>Das Twitter Logo ist Eigentum von <i>Twitter Inc.</i></div>
        </div>
        <div class="footer-item">
            <div class="a-gaps">
                <div><router-link to="/privacy">Datenschutzerklärung</router-link></div>
                <div><a href="https://msikma.github.io/pokesprite/overview/dex-gen8.html" target="_blank" rel="noopener noreferrer">Sprites</a></div>
                <div><a href="https://fontawesome.com/license/free" target="_blank" rel="noopener noreferrer"><i>Fontawesome</i> Lizenz</a></div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>

footer .footer-item {
    @apply p-4 text-xs;
}

footer .footer-item > div {
    @apply mb-2;
}

footer .footer-item > div:last-child {
    @apply m-0;
}

footer .footer-item a {
    @apply underline;
}

footer .footer-item .a-gaps {
    @apply flex gap-4 flex-col md:flex-row;
}

</style>