<template>
  <div class="min-h-screen bg-background dark:bg-dark-background">
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;800&display=swap');

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181819;
  font-family: 'Montserrat', sans-serif;
}

#app.dark {
  color: #a7b9bb;
}

html {
    --primary-color: #158fdb;
    --secondary-color: #ff42a1;
    --contrast-color: #ffffff;
    --background-color: #f8f8ff;
    --font-color: #181819;
}

html[data-theme="dark"] {
    --primary-color: #0068a8;
    --secondary-color: #3ca7ff;
    --contrast-color: #ffffff;
    --background-color: #303030;
    --font-color: #a7b9bb;
}

</style>
