<template>
    <nav class="relative flex items-center justify-between shadow-md bg-background dark:bg-dark-background z-20">

        <div class="nav-element mobile-nav-element">
            <i class="fas fa-bars nav-icon" @click="activateMobileNav"></i>
        </div>
        <router-link to="/">
            <div class="nav-element">
                <div class="nav-image">
                    <img src="../assets/images/shinytracker-icon.png" alt="ShinyTracker Icon" class="w-10 h-10" />
                </div>
                <div class="text-2xl font-semibold">
                    ShinyTracker
                </div>
            </div>
        </router-link>
        <div class="nav-element desktop-nav-element">
            <Navlinks />
        </div>

    </nav>
    
    <transition name="slide">
        <nav class="relative shadow-md mt-2 bg-background dark:bg-dark-background z-10 mobile-collapse-nav" :class="{'active': mobileNavActive}" v-if="mobileNavActive" @click="activateMobileNav">
            <Navlinks />
        </nav>
    </transition>
    
</template>

<script>
import Navlinks from '@/components/Navlinks.vue';

export default {
    name: 'Navbar',
    components: {
        Navlinks
    },
    data() {
        return {
            mobileNavActive: false
        }
    },
    methods: {
        activateMobileNav() {
            if (this.mobileNavActive === true) {
                this.mobileNavActive = false;
            } else {
                this.mobileNavActive = true;
            }
        }
    }
}
</script>

<style>

nav .nav-element {
    @apply flex items-center gap-2 p-4;
}

nav .nav-icon {
    @apply text-3xl cursor-pointer;
}

nav .mobile-nav-element {
    @apply md:hidden;
}

nav .desktop-nav-element {
    @apply hidden md:flex;
}

nav .desktop-nav-element.active {
    @apply flex;
}

nav.mobile-collapse-nav {
    @apply hidden;
}

nav.mobile-collapse-nav.active {
    @apply flex flex-col gap-2 p-2 items-center justify-center md:hidden;
}

/*------ Animation ------*/

.slide-enter-from,
.slide-leave-to {
    @apply transform -translate-y-full opacity-0;
}

.slide-enter-active,
.slide-leave-active {
    @apply transition-all;
}

/* .nav-link configured in /assets/tailwind.css */

</style>