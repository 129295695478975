import { createStore } from 'vuex'

export default createStore({
  state: {
    changelog: [],
    darkTheme: false,
  },
  mutations: {
    setChangelog(state, arr) {
      state.changelog = arr;
    }
  },
  actions: {
  },
  modules: {
  }
})
