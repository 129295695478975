<template>
    <main class="relative z-0 mt-2">
        <div class="flex justify-center my-10">
            <img src="../assets/images/screenshot_lightmode_frame.png" class="h-96 dark:hidden" />
            <img src="../assets/images/screenshot_darkmode_frame.png" class="h-96 hidden dark:block" />
        </div>
        <div class="text-center px-2">
            <div class="font-normal text-base md:text-lg">Die offizielle ShinyTracker App von <a href="https://pokenews.diafischchen.net/" class="text-primary dark:text-dark-secondary underline">pokenews.diafischchen.net</a></div>
            <div class="font-bold md:font-semibold text-base md:text-2xl">
                Aktuelle Version: 
                <span class="font-bold text-red-800 dark:text-red-500" v-if="error">nicht ermittelbar</span>
                <span class="text-sm" v-if="load">loading...</span>
                <span class="font-bold text-primary dark:text-dark-secondary" v-if="!load">{{ version }}</span>
            </div>
        </div>
        <div class="grid md:grid-cols-2 grid-cols-1 gap-4 my-10">
            <a class="button w-60 text-center md:justify-self-end justify-self-center" href="/download.php?file=next" rel="noopener noreferrer">Direct Download</a>
            <a class="button w-60 text-center md:justify-self-start justify-self-center" href="https://play.google.com/store/apps/details?id=net.diafischchen.shinytracker" rel="noopener noreferrer">Google Play</a>
        </div>
    </main>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Download',
    data() {
        return {
            version: '',
            load: true,
            error: false
        }
    },
    async created() {

        if ('versionCode' in sessionStorage) {

            this.version = sessionStorage.getItem('versionCode')

        } else {

            await axios.get('https://api.diafischchen.net/shinytracker/meta/version')
                .then((response) => {
                    //console.log(response);
                    const version = response.data.version;
                    this.version = version;
                    sessionStorage.setItem('versionCode', version)
                })
                .catch(() => {
                    //console.log(error);
                    this.error = true;
                })

        }

        this.load = false;
    }
}
</script>