<template>
    <div class="nav-link">
        <router-link to="/">Download</router-link>
    </div>
    <div class="nav-link">
        <router-link to="/changelog">Changelog</router-link>
    </div>
    <div class="nav-link dark:hidden">
        <a @click="toggleDarkMode"><i class="fas fa-moon nav-icon"></i></a>
    </div>
    <div class="nav-link hidden dark:block">
        <a @click="toggleLightMode"><i class="fas fa-sun nav-icon"></i></a>
    </div>
    <div class="nav-link">
        <a href="https://twitter.com/pokenewsapp"><i class="fab fa-twitter nav-icon"></i></a>
    </div>
</template>

<script>
export default {
    name: 'Navlinks',
    methods: {
        toggleDarkMode() {
            document.getElementById('app').classList.add('dark');
            localStorage.setItem('darkTheme', 'true');
            this.$store.state.darkTheme = true;
        },
        toggleLightMode() {
            document.getElementById('app').classList.remove('dark');
            localStorage.setItem('darkTheme', 'false');
            this.$store.state.darkTheme = false;
        }
    }
}
</script>